import { LanguageCode } from '@models/languageCode';

type TranslatedOptionType =
    | 'titles'
    | 'industry'
    | 'interest2'
    | 'adminService'
    | 'commerce'
    | 'construction'
    | 'education'
    | 'entertainment'
    | 'finance'
    | 'government'
    | 'healthcare'
    | 'hospitality'
    | 'transportation'
    | 'utilities'
    | 'hearAboutUs';
type TranslatedOptionPaths = Record<LanguageCode, string>;

export const translatedOptions: Record<TranslatedOptionType, TranslatedOptionPaths> = {
    titles: {
        [LanguageCode.en]: 'titleOptions/en',
        [LanguageCode.enGb]: 'titleOptions/en',
        [LanguageCode.frCa]: 'titleOptions/fr-fr',
        [LanguageCode.es]: 'titleOptions/es',
        [LanguageCode.it]: 'titleOptions/it',
        [LanguageCode.frFr]: 'titleOptions/fr-fr',
        [LanguageCode.ptBr]: 'titleOptions/pt-br',
        [LanguageCode.de]: 'titleOptions/de',
        [LanguageCode.enAu]: 'titleOptions/en',
        [LanguageCode.esMx]: 'titleOptions/es-mx',
        [LanguageCode.pl]: 'titleOptions/pl',
        [LanguageCode.nl]: 'titleOptions/nl',
        [LanguageCode.se]: 'titleOptions/se',
        [LanguageCode.ae]: 'titleOptions/en',
        [LanguageCode.ie]: 'titleOptions/en',
        [LanguageCode.apac]: 'titleOptions/en',
        [LanguageCode.enCb]: 'titleOptions/en',
        [LanguageCode.idn]: 'titleOptions/en',
    },
    industry: {
        [LanguageCode.en]: 'industryOptions/en',
        [LanguageCode.enGb]: 'industryOptions/en',
        [LanguageCode.frCa]: 'industryOptions/fr-ca',
        [LanguageCode.es]: 'industryOptions/es',
        [LanguageCode.it]: 'industryOptions/it',
        [LanguageCode.frFr]: 'industryOptions/fr-fr',
        [LanguageCode.ptBr]: 'industryOptions/pt-br',
        [LanguageCode.de]: 'industryOptions/de',
        [LanguageCode.enAu]: 'industryOptions/en',
        [LanguageCode.esMx]: 'industryOptions/es-mx',
        [LanguageCode.pl]: 'industryOptions/pl',
        [LanguageCode.nl]: 'industryOptions/nl',
        [LanguageCode.se]: 'industryOptions/se',
        [LanguageCode.ae]: 'industryOptions/en',
        [LanguageCode.ie]: 'industryOptions/en',
        [LanguageCode.apac]: 'industryOptions/en',
        [LanguageCode.enCb]: 'industryOptions/en',
        [LanguageCode.idn]: 'industryOptions/idn',
    },
    adminService: {
        [LanguageCode.en]: 'adminServiceOptions/en',
        [LanguageCode.enGb]: 'adminServiceOptions/en',
        [LanguageCode.frCa]: 'adminServiceOptions/fr-ca',
        [LanguageCode.es]: 'adminServiceOptions/es',
        [LanguageCode.it]: 'adminServiceOptions/it',
        [LanguageCode.frFr]: 'adminServiceOptions/fr-fr',
        [LanguageCode.ptBr]: 'adminServiceOptions/pt-br',
        [LanguageCode.de]: 'adminServiceOptions/de',
        [LanguageCode.enAu]: 'adminServiceOptions/en',
        [LanguageCode.esMx]: 'adminServiceOptions/es-mx',
        [LanguageCode.pl]: 'adminServiceOptions/pl',
        [LanguageCode.nl]: 'adminServiceOptions/nl',
        [LanguageCode.se]: 'adminServiceOptions/se',
        [LanguageCode.ae]: 'adminServiceOptions/en',
        [LanguageCode.ie]: 'adminServiceOptions/en',
        [LanguageCode.apac]: 'adminServiceOptions/en',
        [LanguageCode.enCb]: 'adminServiceOptions/en',
        [LanguageCode.idn]: 'adminServiceOptions/idn',
    },
    commerce: {
        [LanguageCode.en]: 'commerceOptions/en',
        [LanguageCode.enGb]: 'commerceOptions/en',
        [LanguageCode.frCa]: 'commerceOptions/fr-ca',
        [LanguageCode.es]: 'commerceOptions/es',
        [LanguageCode.it]: 'commerceOptions/it',
        [LanguageCode.frFr]: 'commerceOptions/fr-fr',
        [LanguageCode.ptBr]: 'commerceOptions/pt-br',
        [LanguageCode.de]: 'commerceOptions/de',
        [LanguageCode.enAu]: 'commerceOptions/en',
        [LanguageCode.esMx]: 'commerceOptions/es-mx',
        [LanguageCode.pl]: 'commerceOptions/pl',
        [LanguageCode.nl]: 'commerceOptions/nl',
        [LanguageCode.se]: 'commerceOptions/se',
        [LanguageCode.ae]: 'commerceOptions/en',
        [LanguageCode.ie]: 'commerceOptions/en',
        [LanguageCode.apac]: 'commerceOptions/en',
        [LanguageCode.enCb]: 'commerceOptions/en',
        [LanguageCode.idn]: 'commerceOptions/idn',
    },
    construction: {
        [LanguageCode.en]: 'constructionOptions/en',
        [LanguageCode.enGb]: 'constructionOptions/en',
        [LanguageCode.frCa]: 'constructionOptions/fr-ca',
        [LanguageCode.es]: 'constructionOptions/es',
        [LanguageCode.it]: 'constructionOptions/it',
        [LanguageCode.frFr]: 'constructionOptions/fr-fr',
        [LanguageCode.ptBr]: 'constructionOptions/pt-br',
        [LanguageCode.de]: 'constructionOptions/de',
        [LanguageCode.enAu]: 'constructionOptions/en',
        [LanguageCode.esMx]: 'constructionOptions/es-mx',
        [LanguageCode.pl]: 'constructionOptions/pl',
        [LanguageCode.nl]: 'constructionOptions/nl',
        [LanguageCode.se]: 'constructionOptions/se',
        [LanguageCode.ae]: 'constructionOptions/en',
        [LanguageCode.ie]: 'constructionOptions/en',
        [LanguageCode.apac]: 'constructionOptions/en',
        [LanguageCode.enCb]: 'constructionOptions/en',
        [LanguageCode.idn]: 'constructionOptions/idn',
    },
    education: {
        [LanguageCode.en]: 'educationOptions/en',
        [LanguageCode.enGb]: 'educationOptions/en',
        [LanguageCode.frCa]: 'educationOptions/fr-ca',
        [LanguageCode.es]: 'educationOptions/es',
        [LanguageCode.it]: 'educationOptions/it',
        [LanguageCode.frFr]: 'educationOptions/fr-fr',
        [LanguageCode.ptBr]: 'educationOptions/pt-br',
        [LanguageCode.de]: 'educationOptions/de',
        [LanguageCode.enAu]: 'educationOptions/en',
        [LanguageCode.esMx]: 'educationOptions/es-mx',
        [LanguageCode.pl]: 'educationOptions/pl',
        [LanguageCode.nl]: 'educationOptions/nl',
        [LanguageCode.se]: 'educationOptions/se',
        [LanguageCode.ae]: 'educationOptions/en',
        [LanguageCode.ie]: 'educationOptions/en',
        [LanguageCode.apac]: 'educationOptions/en',
        [LanguageCode.enCb]: 'educationOptions/en',
        [LanguageCode.idn]: 'educationOptions/idn',
    },
    entertainment: {
        [LanguageCode.en]: 'entertainmentOptions/en',
        [LanguageCode.enGb]: 'entertainmentOptions/en',
        [LanguageCode.frCa]: 'entertainmentOptions/fr-ca',
        [LanguageCode.es]: 'entertainmentOptions/es',
        [LanguageCode.it]: 'entertainmentOptions/it',
        [LanguageCode.frFr]: 'entertainmentOptions/fr-fr',
        [LanguageCode.ptBr]: 'entertainmentOptions/pt-br',
        [LanguageCode.de]: 'entertainmentOptions/de',
        [LanguageCode.enAu]: 'entertainmentOptions/en',
        [LanguageCode.esMx]: 'entertainmentOptions/es-mx',
        [LanguageCode.pl]: 'entertainmentOptions/pl',
        [LanguageCode.nl]: 'entertainmentOptions/nl',
        [LanguageCode.se]: 'entertainmentOptions/se',
        [LanguageCode.ae]: 'entertainmentOptions/en',
        [LanguageCode.ie]: 'entertainmentOptions/en',
        [LanguageCode.apac]: 'entertainmentOptions/en',
        [LanguageCode.enCb]: 'entertainmentOptions/en',
        [LanguageCode.idn]: 'entertainmentOptions/idn',
    },
    finance: {
        [LanguageCode.en]: 'financeOptions/en',
        [LanguageCode.enGb]: 'financeOptions/en',
        [LanguageCode.frCa]: 'financeOptions/fr-ca',
        [LanguageCode.es]: 'financeOptions/es',
        [LanguageCode.it]: 'financeOptions/it',
        [LanguageCode.frFr]: 'financeOptions/fr-fr',
        [LanguageCode.ptBr]: 'financeOptions/pt-br',
        [LanguageCode.de]: 'financeOptions/de',
        [LanguageCode.enAu]: 'financeOptions/en',
        [LanguageCode.esMx]: 'financeOptions/es-mx',
        [LanguageCode.pl]: 'financeOptions/pl',
        [LanguageCode.nl]: 'financeOptions/nl',
        [LanguageCode.se]: 'financeOptions/se',
        [LanguageCode.ae]: 'financeOptions/en',
        [LanguageCode.ie]: 'financeOptions/en',
        [LanguageCode.apac]: 'financeOptions/en',
        [LanguageCode.enCb]: 'financeOptions/en',
        [LanguageCode.idn]: 'financeOptions/idn',
    },
    government: {
        [LanguageCode.en]: 'governmentOptions/en',
        [LanguageCode.enGb]: 'governmentOptions/en',
        [LanguageCode.frCa]: 'governmentOptions/fr-ca',
        [LanguageCode.es]: 'governmentOptions/es',
        [LanguageCode.it]: 'governmentOptions/it',
        [LanguageCode.frFr]: 'governmentOptions/fr-fr',
        [LanguageCode.ptBr]: 'governmentOptions/pt-br',
        [LanguageCode.de]: 'governmentOptions/de',
        [LanguageCode.enAu]: 'governmentOptions/en',
        [LanguageCode.esMx]: 'governmentOptions/es-mx',
        [LanguageCode.pl]: 'governmentOptions/pl',
        [LanguageCode.nl]: 'governmentOptions/nl',
        [LanguageCode.se]: 'governmentOptions/se',
        [LanguageCode.ae]: 'governmentOptions/en',
        [LanguageCode.ie]: 'governmentOptions/en',
        [LanguageCode.apac]: 'governmentOptions/en',
        [LanguageCode.enCb]: 'governmentOptions/en',
        [LanguageCode.idn]: 'governmentOptions/idn',
    },
    healthcare: {
        [LanguageCode.en]: 'healthcareOptions/en',
        [LanguageCode.enGb]: 'healthcareOptions/en',
        [LanguageCode.frCa]: 'healthcareOptions/fr-ca',
        [LanguageCode.es]: 'healthcareOptions/es',
        [LanguageCode.it]: 'healthcareOptions/it',
        [LanguageCode.frFr]: 'healthcareOptions/fr-fr',
        [LanguageCode.ptBr]: 'healthcareOptions/pt-br',
        [LanguageCode.de]: 'healthcareOptions/de',
        [LanguageCode.enAu]: 'healthcareOptions/en',
        [LanguageCode.esMx]: 'healthcareOptions/es-mx',
        [LanguageCode.pl]: 'healthcareOptions/pl',
        [LanguageCode.nl]: 'healthcareOptions/nl',
        [LanguageCode.se]: 'healthcareOptions/se',
        [LanguageCode.ae]: 'healthcareOptions/en',
        [LanguageCode.ie]: 'healthcareOptions/en',
        [LanguageCode.apac]: 'healthcareOptions/en',
        [LanguageCode.enCb]: 'healthcareOptions/en',
        [LanguageCode.idn]: 'healthcareOptions/idn',
    },
    hospitality: {
        [LanguageCode.en]: 'hospitalityOptions/en',
        [LanguageCode.enGb]: 'hospitalityOptions/en',
        [LanguageCode.frCa]: 'hospitalityOptions/fr-ca',
        [LanguageCode.es]: 'hospitalityOptions/es',
        [LanguageCode.it]: 'hospitalityOptions/it',
        [LanguageCode.frFr]: 'hospitalityOptions/fr-fr',
        [LanguageCode.ptBr]: 'hospitalityOptions/pt-br',
        [LanguageCode.de]: 'hospitalityOptions/de',
        [LanguageCode.enAu]: 'hospitalityOptions/en',
        [LanguageCode.esMx]: 'hospitalityOptions/es-mx',
        [LanguageCode.pl]: 'hospitalityOptions/pl',
        [LanguageCode.nl]: 'hospitalityOptions/nl',
        [LanguageCode.se]: 'hospitalityOptions/se',
        [LanguageCode.ae]: 'hospitalityOptions/en',
        [LanguageCode.ie]: 'hospitalityOptions/en',
        [LanguageCode.apac]: 'hospitalityOptions/en',
        [LanguageCode.enCb]: 'hospitalityOptions/en',
        [LanguageCode.idn]: 'hospitalityOptions/idn',
    },
    transportation: {
        [LanguageCode.en]: 'transportationOptions/en',
        [LanguageCode.enGb]: 'transportationOptions/en',
        [LanguageCode.frCa]: 'transportationOptions/fr-ca',
        [LanguageCode.es]: 'transportationOptions/es',
        [LanguageCode.it]: 'transportationOptions/it',
        [LanguageCode.frFr]: 'transportationOptions/fr-fr',
        [LanguageCode.ptBr]: 'transportationOptions/pt-br',
        [LanguageCode.de]: 'transportationOptions/de',
        [LanguageCode.enAu]: 'transportationOptions/en',
        [LanguageCode.esMx]: 'transportationOptions/es-mx',
        [LanguageCode.pl]: 'transportationOptions/pl',
        [LanguageCode.nl]: 'transportationOptions/nl',
        [LanguageCode.se]: 'transportationOptions/se',
        [LanguageCode.ae]: 'transportationOptions/en',
        [LanguageCode.ie]: 'transportationOptions/en',
        [LanguageCode.apac]: 'transportationOptions/en',
        [LanguageCode.enCb]: 'transportationOptions/en',
        [LanguageCode.idn]: 'transportationOptions/idn',
    },
    utilities: {
        [LanguageCode.en]: 'utilitiesOptions/en',
        [LanguageCode.enGb]: 'utilitiesOptions/en',
        [LanguageCode.frCa]: 'utilitiesOptions/fr-ca',
        [LanguageCode.es]: 'utilitiesOptions/es',
        [LanguageCode.it]: 'utilitiesOptions/it',
        [LanguageCode.frFr]: 'utilitiesOptions/fr-fr',
        [LanguageCode.ptBr]: 'utilitiesOptions/pt-br',
        [LanguageCode.de]: 'utilitiesOptions/de',
        [LanguageCode.enAu]: 'utilitiesOptions/en',
        [LanguageCode.esMx]: 'utilitiesOptions/es-mx',
        [LanguageCode.pl]: 'utilitiesOptions/pl',
        [LanguageCode.nl]: 'utilitiesOptions/nl',
        [LanguageCode.se]: 'utilitiesOptions/se',
        [LanguageCode.ae]: 'utilitiesOptions/en',
        [LanguageCode.ie]: 'utilitiesOptions/en',
        [LanguageCode.apac]: 'utilitiesOptions/en',
        [LanguageCode.enCb]: 'utilitiesOptions/en',
        [LanguageCode.idn]: 'utilitiesOptions/idn',
    },
    interest2: {
        [LanguageCode.en]: 'interest2Options/en',
        [LanguageCode.enGb]: 'interest2Options/en',
        [LanguageCode.frCa]: 'interest2Options/en',
        [LanguageCode.es]: 'interest2Options/es',
        [LanguageCode.it]: 'interest2Options/it',
        [LanguageCode.frFr]: 'interest2Options/fr',
        [LanguageCode.ptBr]: 'interest2Options/en',
        [LanguageCode.de]: 'interest2Options/de',
        [LanguageCode.enAu]: 'interest2Options/en',
        [LanguageCode.esMx]: 'interest2Options/en',
        [LanguageCode.pl]: 'interest2Options/en',
        [LanguageCode.nl]: 'interest2Options/en',
        [LanguageCode.se]: 'interest2Options/en',
        [LanguageCode.ae]: 'interest2Options/en',
        [LanguageCode.ie]: 'interest2Options/en',
        [LanguageCode.apac]: 'interest2Options/en',
        [LanguageCode.enCb]: 'interest2Options/en',
        [LanguageCode.idn]: 'interest2Options/en',
    },
    hearAboutUs: {
        [LanguageCode.en]: 'hearAboutUsOptions/en',
        [LanguageCode.enGb]: 'hearAboutUsOptions/en',
        [LanguageCode.frCa]: 'hearAboutUsOptions/fr-ca',
        [LanguageCode.es]: 'hearAboutUsOptions/es',
        [LanguageCode.it]: 'hearAboutUsOptions/it',
        [LanguageCode.frFr]: 'hearAboutUsOptions/fr-fr',
        [LanguageCode.ptBr]: 'hearAboutUsOptions/pt-br',
        [LanguageCode.de]: 'hearAboutUsOptions/de',
        [LanguageCode.enAu]: 'hearAboutUsOptions/en',
        [LanguageCode.esMx]: 'hearAboutUsOptions/es-mx',
        [LanguageCode.pl]: 'hearAboutUsOptions/en',
        [LanguageCode.nl]: 'hearAboutUsOptions/nl',
        [LanguageCode.se]: 'hearAboutUsOptions/en',
        [LanguageCode.ae]: 'hearAboutUsOptions/en',
        [LanguageCode.ie]: 'hearAboutUsOptions/en',
        [LanguageCode.apac]: 'hearAboutUsOptions/en',
        [LanguageCode.enCb]: 'hearAboutUsOptions/en',
        [LanguageCode.idn]: 'hearAboutUsOptions/en',
    },
};
