import { breakpoints, v2Colors } from '@web-for-marketing/react-ui';

export const customFormStyles = {
    defaultFormBackground: (backgroundEnabled: boolean) => ({
        background: backgroundEnabled
            ? `linear-gradient(180deg, ${v2Colors.core.geotab} 0%, #0080B2D9 100%)`
            : undefined,
    }),
    formWithBackgroundImage: (imageUrl: string, backgroundEnabled: boolean) => ({
        background:
            backgroundEnabled && imageUrl
                ? `linear-gradient(180deg, ${v2Colors.core.geotab} 0%, #0080B2D9 100%), url('${imageUrl}') center / cover no-repeat`
                : undefined,
        backgroundAttachment: 'fixed',
        width: '100%',
    }),
    formContainer: {
        paddingBottom: '1rem',
    },
    headingMargin: {
        marginBottom: '3rem',
    },
    noContentMargin: {
        textAlign: 'center' as const,
        marginTop: '4rem',
        marginBottom: '4rem',
    },
    formBoxContainer: {
        margin: '0 auto 0 auto',
        padding: '0',
        maxWidth: '100%',
    },
    submitButton: {
        flexWrap: 'nowrap' as const,
        [`@media (max-width: ${breakpoints.md})`]: {
            flexWrap: 'wrap' as const,
            height: '100%',
            width: '100%',
        },
    },
};
